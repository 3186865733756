import { alpha, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import CallIcon from "@mui/icons-material/Call";

const Connect = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        background: `linear-gradient(to right, ${
          theme.palette.secondary.main
        },${alpha(theme.palette.secondary.main, 0.9)})`,
        p: 3,
        color: "white",
      }}
    >
      <Grid item xs={12} md={8}>
        <Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: 27, color: "white" }}
            variant="h5"
          >
            Avez-vous bésoin de nos services?{" "}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Button
            color="inherit"
            component="a"
            href={"/contact"}
            variant="outlined"
          >
            Contactez-nous
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Connect;
