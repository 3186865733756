import { Box, Grid, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import Banner from "../../components/includes/Banner";
import HomeServices from "../../components/home/Services";
import AboutHead from "../../components/about/About";
import HomeProduts from "../../components/home/Products";

const Home = () => {
  const theme = useTheme();
  return (
    <Box>
      <Banner />
      <Grid sx={{ p: 3 }} spacing={1} container item xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            display: { md: "none" },
            borderRadius: 5,
            p: 5,
            height: { md: 600, xs: 400 },
            backgroundImage: `url('/rapporteur.jpg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></Grid>
        <Grid item sx={{ display: { xs: "none", md: "flex" } }} md={6}>
          <Typography textAlign={"center"}>
            <img
              style={{ borderRadius: 20 }}
              src="/rapporteur.jpg"
              width={"60%"}
              alt="rapporteur"
            />
          </Typography>
        </Grid>
        <Grid
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          item
          xs={12}
          md={6}
        >
          <Typography
            fontWeight={"500"}
            color={theme.palette.primary.main}
            variant="h6"
          >
            CONSOL360 vise à faire des affaires avec des investisseurs,
            partenaires, bailleurs de fonds ou sociétés commerciales potentiels
            intéressés à faire des affaires en République démocratique du Congo
          </Typography>
        </Grid>
      </Grid>
      <HomeProduts />
      <HomeServices />
    </Box>
  );
};

export default Home;
