import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const AboutHead = () => {
  const theme = useTheme();
  return (
    <Grid container sx={{ p: 3 }}>
      <Grid item xs={12}>
        <Typography
          sx={{ my: 2, color: theme.palette.primary.main }}
          textAlign={"center"}
          variant="h3"
          component={"h1"}
        >
          Consol360 est une société 100% Congolaise.
        </Typography>
        <Typography sx={{ my: 2 }} textAlign={"center"}>
          Nous sommes le partenaire idéal pour vous aider à accéder aux
          opportunités d’affaires en République démocratique du Congo. Consol360
          est bien positionné et bien connecté en tant que leader influent dans
          la région.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutHead;
