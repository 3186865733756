export const menuList = [
  {
    name: "Nos services",
    items: [
      {
        title: "Nettoyage et entretien",
        href: "/",
      },
      {
        title: "Gestion des immondices",
        href: "/",
      },
      {
        title: "Désinfectisation & Dératisation",
        href: "/",
      },
      {
        title:
          "Bâtiment, travaux public, génie civile, maintenance et réparation générale",
        href: "/",
      },
      {
        href: "/",
        title: "Gestion de ressources humaines et placement de personnels ",
      },
      {
        title: "Forage",
        href: "/",
      },
      {
        title: "Communication 360º",
        href: "/",
      },
    ],
  },
  {
    name: "A propos de nous",
    href: "/a-propos",
  },
];
