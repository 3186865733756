import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { useTheme } from "@mui/material";
import { menuList } from "../menuList";
import CallIcon from "@mui/icons-material/Call";

const Topbar = ({ onSidebarOpen }) => {
  const theme = useTheme();

  return (
    <AppBar position="sticky" color="inherit">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography component="a" sx={{ mr: 0.5, mt: 1 }} href={"/"}>
            <img src={"/logo.png"} alt="logo" width={150} />
          </Typography>
          {/* <Box
            sx={{
              ml: 2,
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "none", md: "flex" },
            }}
          >
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ m: 3 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    color: "inherit",
                    fontSize: 17,
                    m: 1,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}
          </Box> */}
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}
          >
            <Button
              component="a"
              target="_blank"
              startIcon={<CallIcon />}
              href="tel:+243819999777"
              variant="contained"
            >
              Contactez-nous
            </Button>
          </Box>
          {/* <Box sx={{ ml: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
            >
              <MenuIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topbar;
