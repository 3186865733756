import About from "./views/about";
import ContactUs from "./views/contact";
import Home from "./views/home";
import Mentions from "./views/mentions";

export default [
  {
    name: "Home",
    href: "/",
    component: <Home />,
  },
  {
    name: "About",
    href: "/a-propos",
    component: <About />,
  },
  {
    name: "Mentions",
    href: "/mentions",
    component: <Mentions />,
  },
  {
    name: "Contact",
    href: "/contact",
    component: <ContactUs />,
  },
];
