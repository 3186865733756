import React from "react";
import { Box } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import Connect from "./includes/Connect";

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <Box>{children}</Box>
      <Box sx={{ p: 6, backgroundColor: "#eee" }}>
        <Connect />
      </Box>
      <Footer />
    </main>
  );
};

export default Layout;
