import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { services } from "../../data";

const HomeProduts = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box sx={{ p: 5, backgroundColor: "#fff" }}>
      <Grid container spacing={2}>
        <Grid sx={{ mb: 4 }} item xs={12}>
          <Typography
            color={theme.palette.primary.main}
            textAlign="center"
            variant="h3"
            component={"h2"}
          >
            Nos produits
          </Typography>
        </Grid>
      </Grid>
      <Grid direction={"row"} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card
            //onClick={() => navigate(service.path)}
            elevation={0}
            sx={{
              height: { md: 700 },
              "&:hover": {
                color: "white",
                background: theme.palette.primary.main,
              },
            }}
          >
            <CardMedia
              sx={{ height: 300 }}
              image={"/eclairage.jpg"}
              title={"Eclairage"}
            />
            <CardContent sx={{ p: 3 }}>
              <Typography
                color="primary"
                sx={{
                  my: 2,
                  fontWeight: "600",
                  "&:hover": {
                    color: "white",
                  },
                }}
                variant="h5"
                textAlign="center"
              >
                ÉCLAIRAGE PUBLIC ET BALISAGE AÉROPORTUAIRE
              </Typography>
              <Typography textAlign="center">
                Distribution, installation, et maintenance des Lampadaires
                solaires, Poteaux fabriqué en alliage d'aluminium à haute
                résistance destiné à l'éclairage des espaces publics, voies
                routières,... et du balisage et équipements aéroportuaire.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            //onClick={() => navigate(service.path)}
            elevation={0}
            sx={{
              height: { md: 700 },
              "&:hover": {
                color: "white",
                background: theme.palette.primary.main,
              },
            }}
          >
            <CardMedia
              sx={{ height: 300 }}
              image={"/bitume.jpg"}
              title={"Bitume"}
            />
            <CardContent sx={{ p: 3 }}>
              <Typography
                color="primary"
                sx={{
                  my: 2,
                  fontWeight: "600",
                  "&:hover": {
                    color: "white",
                  },
                }}
                variant="h5"
                textAlign="center"
              >
                BITUME
              </Typography>
              <Typography textAlign="center">
                CONSOL360 est impliqué dans toutes les étapes de la chaine
                logistique : de l’approvisionnement auprès des raffineries
                jusqu’à à la distribution sur le chantier du client. La qualité
                de nos produits et notre expertise dans ce métier sont reconnues
                par l’ensemble de nos clients, acteurs des Travaux Publics
                intervenant dans la construction d’infrastructures routières et
                autoroutières. CONSOL360 propose une large gamme de grades de
                bitumes qui s’adapte à une variété d’applications routières, y
                compris les grades de pénétration, les grades de viscosité ou
                les grades PG.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            //onClick={() => navigate(service.path)}
            elevation={0}
            sx={{
              height: { md: 700 },
              "&:hover": {
                color: "white",
                background: theme.palette.primary.main,
              },
            }}
          >
            <CardMedia
              sx={{ height: 300 }}
              image={"/petrole.jpg"}
              title={"Petrole"}
            />
            <CardContent sx={{ p: 3 }}>
              <Typography
                color="primary"
                sx={{
                  my: 2,
                  fontWeight: "600",
                  "&:hover": {
                    color: "white",
                  },
                }}
                variant="h5"
                textAlign="center"
              >
                PÉTROLE ET GAZ
              </Typography>
              <Typography textAlign="center">
                CONSOL360 est active sur le marché des produits pétroliers plus
                précisément dans la vente et distribution du pétrole et du gaz.
                Consol360 est impliqué dans toutes les étapes de la chaine
                logistique de la production à la consommation.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            //onClick={() => navigate(service.path)}
            elevation={0}
            sx={{
              height: { md: 700 },
              "&:hover": {
                color: "white",
                background: theme.palette.primary.main,
              },
            }}
          >
            <CardMedia
              sx={{ height: 300 }}
              image={"/fire.jpg"}
              title={"Fire"}
            />
            <CardContent sx={{ p: 3 }}>
              <Typography
                color="primary"
                sx={{
                  my: 2,
                  fontWeight: "600",
                  "&:hover": {
                    color: "white",
                  },
                }}
                variant="h5"
                textAlign="center"
              >
                FIRE AND SAFETY
              </Typography>
              <Typography textAlign="center">
                Vente, recharge et maintenance des extincteurs et du système
                anti incendie
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeProduts;
