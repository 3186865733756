import { createTheme } from "@mui/material";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: "Roboto",
    button: {
      textTransform: "upperCase",
    },
    a: {
      fontWeight: "500",
    },
    h1: {
      fontWeight: "900",
    },
    h2: {
      fontWeight: "900",
    },
    h3: {
      fontWeight: "700",
    },
    h4: {
      fontWeight: "700",
    },
    h5: {
      fontWeight: "600",
    },
    h6: {
      fontWeight: "600",
    },
  },
});

export default theme;
