import React, { Fragment } from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { about } from "../../data";
import { Grid, Paper, Typography } from "@mui/material";
import AboutHead from "../../components/about/About";

const About = () => {
  return (
    <Fragment>
      <Breadcrumb title="A propos de nous" />
      <AboutHead />
      <Grid container sx={{ p: 3, backgroundColor: "#eee" }} spacing={2}>
        {about.map((item) => (
          <Fragment>
            <Grid item xs={12} md={3}>
              <Paper elevation={0} sx={{ p: 3 }}>
                <Typography>{item.details}</Typography>
              </Paper>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Fragment>
  );
};

export default About;
