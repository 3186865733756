import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { services } from "../../data";

const HomeServices = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Grid container sx={{ p: 5, backgroundColor: "#eee" }} spacing={2}>
      <Grid sx={{ mb: 4 }} item xs={12}>
        <Typography
          color={theme.palette.primary.main}
          textAlign="center"
          variant="h3"
          component={"h2"}
        >
          Nos services
        </Typography>
      </Grid>
      {services.map((service) => (
        <Grid item xs={12} md={4}>
          <Card
            onClick={() => navigate(service.path)}
            elevation={0}
            sx={{
              height: { md: 700 },
              "&:hover": {
                color: "white",
                background: theme.palette.primary.main,
              },
            }}
          >
            <CardMedia
              sx={{ height: 300 }}
              image={service.image}
              title={service.title}
            />
            <CardContent sx={{ p: 3 }}>
              <Typography
                color="primary"
                sx={{
                  my: 2,
                  fontWeight: "600",
                  "&:hover": {
                    color: "white",
                  },
                }}
                variant="h5"
                textAlign="center"
              >
                {service.title}
              </Typography>
              <Typography textAlign="center">{service.details}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeServices;
