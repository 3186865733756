import { Button, Alert, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { send } from "emailjs-com";
import Helmet from "react-helmet";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setmessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMesage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    send(
      "service_mzu47sy",
      "template_ztm794h",
      { name, email, message },
      "h7W9uZxQtwrcw0YBp"
    )
      .then((result) => {
        setLoading(false);
        setSuccess(true);
        setName("");
        setEmail("");
        setmessage("");
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setErrorMessage(err.text);
      });
  };

  return (
    <main>
      <Helmet>
        <title>Nous contacter</title>
      </Helmet>
      <Grid container spacing={2} sx={{ p: 5, backgroundColor: "#eee" }}>
        <Grid
          item
          container
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center">
            <img src={"/contact.png"} width={"80%"} alt="contact" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ backgroundColor: "#fff", p: 5 }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {success ? (
                <Alert variant="filled" severity="success">
                  Votre message a bien été envoyé! Merci de nous avoir contacté.
                </Alert>
              ) : null}
              <Grid item xs={12}>
                <Typography sx={{ my: 4 }} variant="h4">
                  Envoyez-nous un message.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  name="name"
                  variant="outlined"
                  required
                  label="Votre nom complet"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  name="email"
                  variant="outlined"
                  label="Adresse email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  name="object"
                  variant="outlined"
                  label="Objet"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  required
                  rows={5}
                  name="message"
                  variant="outlined"
                  label="Votre message"
                  onChange={(e) => setmessage(e.target.value)}
                />
              </Grid>
              <Grid item md={8}></Grid>
              <Grid item xs={12} md={4}>
                <Button
                  type="submit"
                  disabled={loading}
                  fullWidth
                  variant="contained"
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </main>
  );
};

export default ContactUs;
