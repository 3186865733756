import { alpha, Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Slider from "react-slick";

export default () => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        sx={{
          p: 3,
          pb: 10,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.1)},
         ${alpha(theme.palette.primary.main, 0.1)}
      ), url('/bannerIntro.jpg')`,
        }}
      >
        <Grid item sx={{ mt: 10, color: "white" }} xs={12} md={6}>
          <Typography variant="h2" component="h1">
            CONSOL360
          </Typography>
          <Typography variant="h5" color={theme.palette.secondary.main}>
            SOLUTION SUR-MESURE
          </Typography>
          <Typography>
            « La République Démocratique du Congo est une terre d'opportunités.»
            En tant qu'entreprise congolaise, Consol360 met en avant et présente
            les opportunités d'affaires disponibles en République démocratique
            du Congo aux potentiels bailleurs de fonds, aux investisseurs, aux
            partenaires extérieurs et aux sociétés commerciales. <br />
            Consol360 est bien positionné et connecté en tant que leader
            influant dans la région. <br />
            Consol360 sécurise les intérêts des partenaires investisseurs pour
            avoir un retour sur investissement et facilite les enregistrements
            des sociétés en République Democratique du Congo.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
