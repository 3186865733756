import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const Breadcrumb = ({ title, content }) => {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        my: 0.1,
        p: 5,
        color: "white",
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center">
          {title}
        </Typography>
        <Typography textAlign="center">{content}</Typography>
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
